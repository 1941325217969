import React, {
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import format from 'string-template';

import { DateFormat } from '../../../../../../utils/date';

import {
  StyledLoadingPage,
  Container,
  ActivityItem,
  ContentContainer,
  Tag,
  ContentText,
} from './styles';
import texts from './texts';

const ActivityList = ({
  history,
  isReady,
}) => {
  const groupedActivities = useMemo(() => {
    if (history.length === 0) return {};

    // Map the history documents to the event text
    const activities = history.map((historyDoc) => ({
      id: `${historyDoc.id}`,
      tag: moment(historyDoc.createdAt).format(DateFormat.DATE_FORMAT_COMMA),
      text: format(
        texts.eventText[historyDoc.type],
        { userName: historyDoc.actionedByUserName || '-' },
      ),
    }));

    // Group the activities by the date
    return activities.reduce((acc, activity) => {
      if (!acc[activity.tag]) {
        acc[activity.tag] = [];
      }
      acc[activity.tag].push(activity);
      return acc;
    }, {});
  }, [history]);

  if (!isReady) {
    return <StyledLoadingPage />;
  }

  return (
    <Container>
      {Object.keys(groupedActivities).length ? (
        Object.keys(groupedActivities).map((activityDate) => (
          <ActivityItem key={activityDate}>
            <Tag>{activityDate}</Tag>
            <ContentContainer>
              {groupedActivities[activityDate].map((activity) => (
                <ContentContainer key={activity.id}>
                  <ContentText>{`- ${activity.text}`}</ContentText>
                </ContentContainer>
              ))}
            </ContentContainer>
          </ActivityItem>
        ))
      ) : texts.emptyText}
    </Container>
  );
};

ActivityList.propTypes = {
  history: PropTypes.array.isRequired,
  isReady: PropTypes.bool.isRequired,
};

export default ActivityList;
