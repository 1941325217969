import { Collection } from 'firestorter';
import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

/**
 * Enum for coach history event types.
 */
const HistoryEventType = {
  WHITE_LISTED: 'WHITE_LISTED',
  ACCOUNT_CREATED: 'ACCOUNT_CREATED',
  PROFILE_SET: 'PROFILE_SET',
  STRIPE_CONNECTED: 'STRIPE_CONNECTED',
  LEAD_FORM_CREATED: 'LEAD_FORM_CREATED',
  POST_PAYMENT_FORM_LINKED: 'POST_PAYMENT_FORM_LINKED',
  FIRST_PRODUCT_CREATED: 'FIRST_PRODUCT_CREATED',
  LOGO_SUBMITTED: 'LOGO_SUBMITTED',
  LOGO_APPROVED: 'LOGO_APPROVED',
  WEBSITE_SUBMITTED: 'WEBSITE_SUBMITTED',
  WEBSITE_APPROVED: 'WEBSITE_APPROVED',
  WELCOME_PACK_SUBMITTED: 'WELCOME_PACK_SUBMITTED',
  APP_LOGO_SET: 'APP_LOGO_SET',
  APP_THEME_SET: 'APP_THEME_SET',
  SLACK_CHANNEL_SET: 'SLACK_CHANNEL_SET',
  COACH_LAUNCHED: 'COACH_LAUNCHED',
  FIRST_LEAD: 'FIRST_LEAD',
  FIRST_SUBSCRIPTION: 'FIRST_SUBSCRIPTION',
};

/**
 * List of events that is concerned with a coach's onboarding process.
 */
const CoachOnboardingEventList = [
  HistoryEventType.ACCOUNT_CREATED,
  HistoryEventType.PROFILE_SET,
  HistoryEventType.STRIPE_CONNECTED,
  HistoryEventType.LEAD_FORM_CREATED,
  HistoryEventType.POST_PAYMENT_FORM_LINKED,
  HistoryEventType.FIRST_PRODUCT_CREATED,
  HistoryEventType.LOGO_SUBMITTED,
  HistoryEventType.LOGO_APPROVED,
  HistoryEventType.WEBSITE_SUBMITTED,
  HistoryEventType.WEBSITE_APPROVED,
  HistoryEventType.WELCOME_PACK_SUBMITTED,
  HistoryEventType.APP_LOGO_SET,
  HistoryEventType.APP_THEME_SET,
  HistoryEventType.SLACK_CHANNEL_SET,
  HistoryEventType.COACH_LAUNCHED,
  HistoryEventType.FIRST_LEAD,
  HistoryEventType.FIRST_SUBSCRIPTION,
];

/**
 * List of events that require manual action to record.
 */
const ManualCoachEventList = [
  HistoryEventType.LOGO_SUBMITTED,
  HistoryEventType.LOGO_APPROVED,
  HistoryEventType.WEBSITE_SUBMITTED,
  HistoryEventType.WEBSITE_APPROVED,
  HistoryEventType.WELCOME_PACK_SUBMITTED,
  HistoryEventType.COACH_LAUNCHED,
];

/**
 * Class representing a coach's history.
 * Stores documents tracking important milestone events of a coach.
 *
 * @class CoachHistory
 * @extends BaseDocument
 */
class CoachHistory extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.COACH_HISTORY}/${id}`, opts);
  }

  /**
   * Gets the coach ID associated with the history item.
   * @return {string}
   */
  get coachId() {
    return this.data.coachId;
  }

  /**
   * Gets the creation timestamp of the history item.
   * @return {Date}
   */
  get createdAt() {
    return this.data.createdAt?.toDate();
  }

  /**
   * Gets the ID of the user who actioned this history item.
   * @return {string}
   */
  get actionedBy() {
    return this.data.actionedBy;
  }

  /**
   * Gets the username of the user who actioned this history item.
   * @return {string}
   */
  get actionedByUserName() {
    return this.data.actionedByUserName;
  }

  /**
   * Gets the type of the history event.
   * The value corresponds to {@link HistoryEventType}.
   * @return {string}
   */
  get type() {
    return this.data.type;
  }

  /**
   * Retrieves the history of a specific coach.
   *
   * @param {string} coachId - The ID of the coach.
   * @returns {Promise<Collection>} A promise resolving to the coach's history collection.
   */
  static async getCoachHistoryById(coachId) {
    const coachHistoryCollection = new Collection(Collections.COACH_HISTORY, {
      createDocument: ({ id }, opts) => new CoachHistory(id, opts),
      query: (ref) => ref
        .where('coachId', '==', coachId)
        .orderBy('createdAt', 'desc'),
    });
    await coachHistoryCollection.fetch();
    return coachHistoryCollection;
  }

  /**
   * Retrieves the history doc for a coach lead by onboarding doc id.
   *
   * @param {string} onboardingDocId - The ID of the onboarding doc.
   * @returns {Promise<Collection>} A promise resolving to the history document.
   */
  static async getCoachHistoryByOnboardingDocId(onboardingDocId) {
    const historyDoc = new CoachHistory(onboardingDocId);
    await historyDoc.init();
    return historyDoc.exists ? historyDoc : null;
  }

  /**
   * Adds a new history item for a coach.
   *
   * @param {object} params - Parameters for the new history item.
   * @param {string} params.type - The type of the history event.
   * @param {string} params.coachId - The ID of the coach.
   * @param {string} params.actionedBy - The ID of the user who took action on the item.
   * @param {string} params.actionedByUserName - The username of the acted user.
   * @param {Date} [params.createdAt=new Date()] - The creation timestamp.
   * @returns {Promise<void>}
   */
  static async addCoachHistoryItem({
    type,
    coachId,
    actionedBy,
    actionedByUserName,
  }) {
    const item = {
      type,
      coachId,
      actionedBy,
      actionedByUserName,
      createdAt: new Date(),
    };

    const coachHistoryCollection = new Collection(Collections.COACH_HISTORY);
    await coachHistoryCollection.add(item);
  }
}

export default CoachHistory;
export {
  HistoryEventType,
  CoachOnboardingEventList,
  ManualCoachEventList,
};
