import { Collection } from 'firestorter';
import BaseDocument from '../../Model/BaseDocument';
import Collections from '../../utils/collections';

const FormState = {
  NEW: 'NEW',
  EDIT: 'EDIT',
};

class Form extends BaseDocument {
  constructor(id, opts) {
    super(`${Collections.FORMS}/${id}`, opts);
  }

  get formName() {
    return this.data.formName;
  }

  get coach() {
    return this.data.coach;
  }

  get coachId() {
    return this.data.coachId;
  }

  get hiddenFields() {
    return this.data.hiddenFields;
  }

  get fields() {
    return this.data.fields;
  }

  get endScreenContent() {
    return this.data.endScreenContent;
  }

  get isArchived() {
    return !!this.data.isArchived;
  }

  /**
   * Gets the forms.
   *
   * @param {boolean} fetchArchivedForms - Whether or not to fetch archived forms
   * @returns {Promise<Collection>} The collection of forms from firestore
   */
  static async getForms(fetchArchivedForms = false) {
    const formsCollection = new Collection(Collections.FORMS, {
      createDocument: ({ id }, opts) => new Form(id, opts),
      query: (ref) => {
        let query = ref.orderBy('coach', 'asc');
        if (!fetchArchivedForms) {
          query = query.where('isArchived', '==', false);
        }
        return query;
      },
    });
    await formsCollection.fetch();
    return formsCollection;
  }

  static async addForm(data) {
    const formsCollection = new Collection(Collections.FORMS);
    const { id } = await formsCollection.add(data);
    return id;
  }

  /**
   * Archives a form.
   * @return {Promise<void>}
   */
  async archive() {
    return this.updateFields({
      isArchived: true,
    });
  }

  /**
   * Unarchives a form.
   * @return {Promise<void>}
   */
  async unarchive() {
    return this.updateFields({
      isArchived: false,
    });
  }
}

export default Form;
export { FormState };
